import { userService, empresaService } from '../_services';
import { router } from '../_helpers';

const user = JSON.parse(localStorage.getItem('token'));
const tipoBase = localStorage.getItem('tipo');
let tipo = null;
if (tipoBase == null) tipo = JSON.parse(tipoBase);

const state = user ? { status: { loggedIn: true }, user: user, tipo: tipo } : { status: {}, user: null, tipo: null };

const actions = {
  login({ dispatch, commit }, { userName, password, empresa }) {
    userService
      .login(userName, password, empresa)
      .then(
        user => {
          let flag = false;

          // Faz download das logomarcas
          empresaService.getLogomarcas().then(response => {
            const logomarcas = response.data;
            flag = true;

            // Atualiza a store de logomarcas
            dispatch('logomarca/fill', logomarcas, { root: true });

            // Adiciona no localstorage as logomarcas
            localStorage.setItem('logomarcas', JSON.stringify(logomarcas));

            if (flag) {
              // Atualiza store de loginSuccess
              commit('loginSuccess', { user: user.token, tipoDemanda: user.tipo });

              // atualiza store das empresas
              dispatch('empresa/fill', user.empresas, { root: true });

              const empAtual = user.empresas[0]

              empresaService
                .findInfoEmpresa(empAtual.id)
                .then(response => {
                  dispatch('empresa/select', response.data, { root: true })
                })
                
              router.push('/');
            }
          });
        },
        error => {
          var errorTxt = error;
          if (error.response) {
            errorTxt = error.response.data.message;
          }
          commit('loginFailure', errorTxt);
          dispatch('alert/error', errorTxt, { root: true });
        }
      )
      .catch(error => {
        commit('loginFailure', error);
        dispatch('alert/error', error, { root: true });
      });
  },
  logout({ dispatch, commit }) {
    userService.logout();
    commit('logout');
    dispatch('empresa/clear', null, { root: true });
  },
  register({ dispatch, commit }, { user }) {
    commit('registerRequest', user);

    userService
      .register(user)
      .then(
        user => {
          commit('registerSuccess', user);
          router.push('/login');
          setTimeout(() => {
            // display success message after route change completes
            dispatch(
              'alert/success',
              'O registro foi realizado com sucesso. Verifique sua caixa de email para confirmação.',
              { root: true }
            );
          });
        },
        error => {
          var errorTxt = error;
          if (error.response) {
            errorTxt = error.response.data.message;
          }
          commit('registerFailure', errorTxt);
          dispatch('alert/error', errorTxt, { root: true });
        }
      )
      .catch(error => {
        console.log('error.catch!.response.data=>', error);

        commit('registerFailure', error);
        dispatch('alert/error', error, { root: true });
      });
  }
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, { user, tipoDemanda }) {
    state.status = { loggedIn: true };
    state.user = user;
    state.tipo = tipoDemanda;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
    state.tipo = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
    state.tipo = null;
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  }
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations
};
