import Vue from 'vue';
import App from './App.vue';

import { router } from './_helpers';
import { store } from './_store';

Vue.prototype.$externalConfig = window.config;

import BootstrapVue from 'bootstrap-vue';
Vue.use(BootstrapVue);

Vue.config.productionTip = true;

// import './assets/scss/eixosp/_variables.scss';
import NowUiKit from "./plugins/now-ui-kit";
// const nowUi = () => import('./plugins/now-ui-kit');
Vue.use(NowUiKit);

/* Material Design Icons */
import 'vue-material-design-icons/styles.css';

/* Google Maps */
import GoogleMaps from './plugins/googleMaps';
Vue.use(GoogleMaps);

/* Axios */
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === 'production') axios.defaults.baseURL = Vue.prototype.$externalConfig.VUE_APP_API_LOCATION;
else axios.defaults.baseURL = process.env.VUE_APP_API_LOCATION;

axios.defaults.headers.common.Accept = 'application/json';
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch('account/logout');
    }

    return Promise.reject(error);
  }
);

/** VeeValidate */
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors'
});

/** Notifications */
import Notifications from 'vue-notification';
Vue.use(Notifications);

/** FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';

import { faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons'

import {
  faCalendarAlt,
  faCircleQuestion,
  faComment,
  faEnvelopeOpenText,
  faEquals,
  faFileDownload,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLock,
  faMapMarkerAlt,
  faSignOutAlt,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faCalendarAlt,
  faCircleQuestion,
  faComment,
  faEnvelopeOpenText,
  faEquals,
  faFileDownload,
  faInfoCircle,
  faKey,
  faLayerGroup,
  faLock,
  faMapMarkerAlt,
  faSignOutAlt,
  faWindowClose,
  faXTwitter,
  faTiktok
);

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
Vue.component('font-awesome-icon', FontAwesomeIcon);

/** Locale config */
import numeral from 'numeral';
import 'numeral/locales';
Vue.filter('numeral', (value, format) => {
  if (!value) {
    return '';
  }

  numeral.locale('pt-br');
  return numeral(value).format(format);
});

// Moment.JS para formatação Datas e Horas
import moment from 'moment';
Vue.filter('formatDate', value => {
  if (value) {
    return moment(value).format('DD/MM/YYYY');
  }
});

Vue.filter('formatShortDate', value => {
  if (value) {
    return moment(value).format('MM/YYYY');
  }
});

Vue.filter('formatDateTime', value => {
  if (value) {
    return moment(value).format('DD/MM/YYYY HH:mm');
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

/*
$(function () {
  $('.dropdown-toggle').dropdown();
}); 
*/
