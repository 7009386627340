import Vue from 'vue';

import { authHeader } from '../_helpers';

export const empresaService = {
  find,
  findAll,
  getLogomarcas,
  findEmpresas,
  findInfoEmpresa
};

function find() {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/empresas`, requestCfg);
}

function findEmpresas() {
  return Vue.axios.get('/empresas/all');
}

function findAll() {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/empresas/all`, requestCfg);
}

function findInfoEmpresa(idEmpresa) {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/empresas/infos/${idEmpresa}`, requestCfg);
}

function getLogomarcas() {
  const requestCfg = {
    method: 'GET',
    headers: authHeader()
  };

  return Vue.axios.get(`/downloads/logomarcas`, requestCfg);
}
